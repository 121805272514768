import React, { Component } from 'react';
import styled from '@emotion/styled';
import convertHtml from '../../utils/convertHtml';
import FormScreenshot from '../../images/assets/socialIcons/newsletter-form.jpg';
import { NEWSLETTER_LIST_ID, NEWSLETTER_URL } from '../../utils/constants';
import Arrow from '../../components/ButtonNotUnderlined/assets/arrow.svg';

const BlogParagraphTextContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1150px) {
    width: 90%;
    margin-left: 5%;
  }
`;

const BlogParagraphTextContainer = styled.div`
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 180%;
  color: #ffffff;

  p:not(:last-child) {
    margin-bottom: 1rem;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 18px;
  }

  em {
    font-style: normal;
  }
  strong {
    font-weight: bold;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  a {
    color: ${props => props.themeColor};
    cursor: pointer;
  }
  @media (max-width: 1150px) {
    width: 90%;
  }
  @media (max-width: 750px) {
    width: 100%;
  }
`;

const BlogParagraphTextContainerInner = styled.div`
  width: 70%;
  max-width: 800px;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;

const BlogWithNewsletter = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;

  > div:first-child > div:first-child {
    width: 100%;
    max-width: unset;
  }

  @media only screen and (max-width: 48em) {
    flex-wrap: wrap;
  }
`;

const BlogWithNewsletterContent = styled.div`
  width: 70%;
  padding-right: 50px;
  @media only screen and (max-width: 48em) {
    width: 100%;
    padding-right: 0;
  }
`;

const BlogWithNewsletterSignup = styled.div`
  width: 30%;
  position: sticky;
  top: 100px;
  background: #212121;
  padding: 30px;

  h4 {
    font-weight: 800;
    margin-bottom: 20px;
    font-size: 20px;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.3;
  }

  img {
    width: 100%;
  }

  @media only screen and (max-width: 48em) {
    // display: none !important;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const LoadingText = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const SubscribeFormMessage = styled.div`
  width: 300px;
  font-weight: bold;
  height: 40px;
  display: flex;
  align-items: center;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const ErrorMsgContainer = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: -10px;
`;

const SubscribeFormContainer = styled.div`
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const SubscribeFormInput = styled.input`
  width: 100%;
  height: 35px;
  background-color: #fff;
  border-width: 0px;
  box-sizing: border-box;
  margin-bottom: 15px;
  padding: 10px;
  text-align: center;
  outline: none;
`;

const SubscribeButton = styled.button`
  display: block;
  border: 0;
  background: #fff;
  color: #212121;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  padding: 12px 20px;
  font-size: 14px;
  text-align: center;
`;

const ArrowContainer = styled.div`
  margin-left: 10px;
  padding-top: 1px;
  display: flex;
  align-items: center;
`;

export class BlogParagraphText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      errorMsg: false,
      isLoading: false,
      disabled: false,
      signedUp: false,
      buttonActive: false,
      signingUp: false,
    };
  }

  updateInputValue = evt => {
    //console.log(evt);
    this.setState({
      inputValue: evt.target.value,
    });
  };

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  toggleButtonActive = () => {
    if (this.state.buttonActive) {
      this.setState({
        buttonActive: false,
      });
    } else {
      this.setState({
        buttonActive: true,
      });
    }
    //console.log(this.state.buttonActive);
  };

  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  onFormSubmit = e => {
    e.preventDefault();
    this.setState({
      signingUp: true,
    });
    const { inputValue, errorMsg } = this.state;

    console.log(inputValue);
    console.log('steve');

    if (this.validateEmail(inputValue)) {
      this.setState({ errorMsg: false });

      const options = {
        method: 'POST',
        headers: {
          revision: '2023-09-15',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            type: 'subscription',
            attributes: {
              custom_source: 'testing',
              profile: {
                data: {
                  type: 'profile',
                  attributes: {
                    email: inputValue,
                  },
                },
              },
            },
            relationships: {
              list: {
                data: {
                  type: 'list',
                  id: NEWSLETTER_LIST_ID,
                },
              },
            },
          },
        }),
      };

      fetch(
        'https://a.klaviyo.com/client/subscriptions/?company_id=P5zdyu',
        options
      )
        .then(response => {
          this.setState({
            signedUp: true,
            signingUp: false,
          });
          console.log('success');
        })
        .catch(err => {
          console.log('error');
          this.setState({
            signingUp: false,
          });
        });
    } else {
      console.log('hero');
      this.setState({ errorMsg: true });
      this.setState({
        signingUp: false,
      });
    }
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      var elements = document.querySelectorAll('.blog-signup-box-holder');
      // Horrible hack to hide the newsletters when multiple paragraphtexts
      var i = 1;
      while (i < 20) {
        if (elements[i]) {
          elements[i].classList.add('blog-signup-box-holder--duplicate');
        }
        i++;
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let paraString = this.props.text_content.html;
    paraString = paraString.split('&lt;br&gt;&lt;/br&gt;').join('<br></br>');
    paraString = paraString.split('&lt;br&gt;').join('<br>');
    paraString = paraString.split('&lt;/br&gt;').join('</br>');
    return (
      <BlogParagraphTextContainerOuter className="blog-signup-box-holder">
        <BlogWithNewsletter>
          <BlogWithNewsletterContent>
            <BlogParagraphTextContainerInner>
              <BlogParagraphTextContainer
                dangerouslySetInnerHTML={convertHtml(paraString)}
                themeColor={this.props.customData.post_color_theme}
              ></BlogParagraphTextContainer>
            </BlogParagraphTextContainerInner>
          </BlogWithNewsletterContent>
          <BlogWithNewsletterSignup className="blog-with-newsletter-signup">
            <>
              <h4>Newsletter Signup</h4>
              <p>
                Sign up to receive all of the latest news and opinions from
                Reload Digital
              </p>
            </>
            {this.state.signingUp ? (
              <LoadingText>Signing Up...</LoadingText>
            ) : (
              <React.Fragment>
                {this.state.signedUp ? (
                  <SubscribeFormMessage>
                    Thank you for signing up! Please confirm your email address.
                  </SubscribeFormMessage>
                ) : (
                  <React.Fragment>
                    {this.state.errorMsg && (
                      <ErrorMsgContainer>
                        Please enter a valid email address
                      </ErrorMsgContainer>
                    )}
                    <form onSubmit={this.onFormSubmit}>
                      <SubscribeFormContainer>
                        <SubscribeFormInput
                          name={'email'}
                          type={'email'}
                          value={this.state.inputValue}
                          onChange={this.updateInputValue}
                          placeholder={'Email Address'}
                        />
                        <SubscribeButton
                          type="submit"
                          whileHover={{ translateX: '5px' }}
                          onHoverStart={() => this.toggleButtonActive()}
                          onHoverEnd={() => this.toggleButtonActive()}
                        >
                          Submit
                        </SubscribeButton>
                      </SubscribeFormContainer>
                    </form>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </BlogWithNewsletterSignup>
        </BlogWithNewsletter>
      </BlogParagraphTextContainerOuter>
    );
  }
}

export default BlogParagraphText;
